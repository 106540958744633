import { HandlerContext } from '../contexts/ActionContext';
import { LogLevel } from '../contexts/LoggerContext';
import { MageOrder, MageProduct } from './Magento.types';

export const parseMageOrder = (apiData: any, shipmentIds: number[] = []) => {
	const mageOrder: MageOrder = {
		// _blob: apiData,
		publicId: apiData['increment_id'],
		techId: apiData['entity_id'],
		currency: apiData['order_currency_code'],
		items: apiData['items'].map((item: any) => {
			const mageProduct: MageProduct = {
				// _blob: item,
				name: item['name'],
				techId: item['item_id'],
				baseSku: item['sku'],
				customSku: item['sku'],
				qty: {
					ordered: item['qty_ordered'],
					invoiced: item['qty_invoiced'],
					shipped: item['qty_shipped'],
					refunded: item['qty_refunded'],
					canceled: item['qty_canceled'],
					availableToShip:
						parseInt(item['qty_ordered'], 10) -
						parseInt(item['qty_shipped'], 10) -
						parseInt(item['qty_refunded'], 10) -
						parseInt(item['qty_canceled'], 10),
				},
				value: item['base_price_incl_tax'],
				weight: (item['row_weight'] / item['qty_ordered']) * 1000,
			};

			return mageProduct;
		}),
		customer: {
			// _blob: '',
			firstname: apiData['customer_firstname'],
			lastname: apiData['customer_lastname'],
			email: apiData['customer_email'],
			phone: apiData['billing_address']['telephone'],
			shipping: {
				// _blob:
				// 	apiData['extension_attributes'][
				// 		'shipping_assignments'
				// 	][0]['shipping']['address'],
				type: 'shipping',
				city: apiData['extension_attributes'][
					'shipping_assignments'
				][0]['shipping']['address']['city'],
				company:
					apiData['extension_attributes']['shipping_assignments'][0][
						'shipping'
					]['address']['company'],
				country:
					apiData['extension_attributes']['shipping_assignments'][0][
						'shipping'
					]['address']['country_id'],
				email: apiData['extension_attributes'][
					'shipping_assignments'
				][0]['shipping']['address']['email'],
				id: 1,
				firstname:
					apiData['extension_attributes']['shipping_assignments'][0][
						'shipping'
					]['address']['firstname'],
				lastname:
					apiData['extension_attributes']['shipping_assignments'][0][
						'shipping'
					]['address']['lastname'],
				postcode:
					apiData['extension_attributes']['shipping_assignments'][0][
						'shipping'
					]['address']['postcode'],
				street: apiData['extension_attributes'][
					'shipping_assignments'
				][0]['shipping']['address']['street'].join(' '),
				phone: apiData['extension_attributes'][
					'shipping_assignments'
				][0]['shipping']['address']['telephone'],
			},
			billing: {
				// _blob: apiData['billing_address'],
				type: 'billing',
				city: apiData['billing_address']['city'],
				company: apiData['billing_address']['company'],
				country: apiData['billing_address']['country_id'],
				email: apiData['billing_address']['email'],
				id: 1,
				firstname: apiData['billing_address']['firstname'],
				lastname: apiData['billing_address']['lastname'],
				postcode: apiData['billing_address']['postcode'],
				street: apiData['billing_address']['street'].join(' '),
				phone: apiData['billing_address']['telephone'],
			},
		},
		invoices: [],
		shipments: shipmentIds,
		storeId: apiData['store_id'],
		date: new Date(apiData['created_at']),
		method: apiData['extension_attributes']['shipping_assignments'][0][
			'shipping'
		]['method'],
	};

	return mageOrder;
};

export const canShipOrderEU = async (
	orderData: MageOrder,
	context: HandlerContext,
) => {
	if (!orderData) {
		return false;
	}

	const { method } = orderData;
	const { addLog } = context;

	if (
		method === 'tig_gls_tig_gls' ||
		method.startsWith('GLSTableRates') ||
		method === 'custom_shipping_custom_shipping'
	) {
		return true;
	}

	if (
		orderData.method === 'mpcustomshipping_mpcustomshipping' ||
		orderData.method === 'mppickupshipping_mppickupshipping'
	) {
		addLog(
			'app',
			`This order will be picked up from Uithoorn`,
			LogLevel.warning,
		);

		return false;
	}

	addLog(
		'app',
		`Will not generate label: This order is not GLS/EU`,
		LogLevel.warning,
	);

	return false;
};

export enum OrderDataAction {
	'unset' = 'unset',
	'set' = 'set',
	'export' = 'export',
}

export const getOrderData = async (
	orderId: string,
	context: HandlerContext,
	setOrder: OrderDataAction = OrderDataAction.set,
	getShipments = false,
): Promise<MageOrder> => {
	const { addLog, mageOrderApi, setMageOrder, setIsLoading } = context;

	setMageOrder(null);
	setIsLoading(true);
	addLog('app', `Fetching ${orderId}`);
	const orderData = await mageOrderApi.getOrder(orderId.trim(), getShipments);

	if (!orderData) {
		addLog('APP', `Could not find order`, LogLevel.error);
		throw new Error('Order not found');
	}

	if (setOrder === OrderDataAction.unset) {
		setMageOrder(null);
	} else {
		setMageOrder(orderData, setOrder === OrderDataAction.export);
	}

	return orderData;
};

export const orderToShipId = async (
	orderId: string,
	context: HandlerContext,
	createShipment?: boolean,
) => {
	const { addLog, mageOrderApi } = context;
	const orderData = await getOrderData(orderId, context);

	if (!orderData) {
		addLog('API', `Order [${orderId}] not found`, LogLevel.error);

		return false;
	}

	if (!(await canShipOrderEU(orderData, context))) {
		return false;
	}

	const shipmentId = createShipment
		? await mageOrderApi.createShipment(orderData)
		: await mageOrderApi.getShipmentId(orderData.techId);

	if (!shipmentId) {
		addLog(
			'app',
			`Could not find [${orderId}|${shipmentId}]`,
			LogLevel.warning,
		);

		return false;
	}

	if (shipmentId.message) {
		addLog('mage', shipmentId.message, LogLevel.error);

		return false;
	}

	return shipmentId;
};

export const updateStatus = async (
	orderId: string,
	context: HandlerContext,
	status: string,
	notifyCustomer = true,
) => {
	const { addLog, mageOrderApi } = context;

	const orderData = await getOrderData(orderId, context);
	const statusUpdate = await mageOrderApi.updateStatus(
		orderData.techId,
		status,
		notifyCustomer ? 1 : 0,
	);

	if (statusUpdate && statusUpdate.message) {
		addLog(
			'app',
			`Something went wrong updating the order status`,
			LogLevel.error,
		);

		return false;
	}

	if (!statusUpdate) {
		addLog('app', `Could not update status`, LogLevel.error);

		return false;
	}

	addLog('app', `Updated status`, LogLevel.success);

	return true;
};
