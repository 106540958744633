import { Table, TableProps } from 'antd';

import { MageOrder, MageProduct } from '../../classes/Magento.types';
import FormInput from '../FormInput/FormInput';
import ProductImage from '../ProductImage/ProductImage';
import styles from './OrderDisplayItemRow.module.scss';

interface Props {
	item: MageProduct;
	order: MageOrder;
	isExport: boolean;
}

const columns: (
	isExport: boolean,
	currency: string,
) => TableProps<MageProduct>['columns'] = (
	isExport: boolean,
	currency: string,
) => {
	const columns = [
		{
			title: 'Ordered',
			key: 'ordered',
			render: (item: MageProduct) => <span>{item.qty.ordered}</span>,
		},
		// {
		// 	title: 'Invoiced',
		// 	key: 'invoiced',
		// 	render: (item: MageProduct) => <span>{item.qty.invoiced}</span>,
		// },
		{
			title: 'Shipped',
			key: 'shipped',
			render: (item: MageProduct) => <span>{item.qty.shipped}</span>,
		},
		// {
		// 	title: 'Refunded',
		// 	key: 'refunded',
		// 	render: (item: MageProduct) => <span>{item.qty.refunded}</span>,
		// },
		// {
		// 	title: 'Canceled',
		// 	key: 'canceled',
		// 	render: (item: MageProduct) => <span>{item.qty.canceled}</span>,
		// },
	];

	if (isExport) {
		columns.push(
			{
				title: `Value (${currency})`,
				key: 'value',
				render: (item: MageProduct) => (
					<FormInput
						name={`items[${item.techId}].value`}
						label=''
						isNumeric
						hasDecimals
					/>
				),
			},
			{
				title: 'Weight (grams)',
				key: 'weight',
				render: (item: MageProduct) => (
					<FormInput
						name={`items[${item.techId}].weight`}
						label=''
						isNumeric
						hasDecimals
					/>
				),
			},
		);
	}

	columns.push({
		title: 'Ship',
		key: 'toship',
		render: (item: MageProduct) => (
			<div
				className={styles.toShip}
				style={{
					backgroundColor:
						item.qty.availableToShip === 0
							? 'rgba(255, 0, 0, 0.6)'
							: '',
				}}
			>
				{item.qty.availableToShip}
			</div>
		),
	});

	return columns;
};

function OrderDisplayItemRow(props: Props) {
	const {
		item,
		isExport,
		order: { currency },
	} = props;

	return (
		<div key={item.techId}>
			<div className={styles.container}>
				<div className={styles.containerInner}>
					<div>
						<ProductImage sku={item.baseSku} />
					</div>
					<div className={styles.row}>
						<div className={styles.sku}>{item.baseSku}</div>
						<div className={styles.name}>{item.name}</div>
						<Table
							dataSource={[item]}
							columns={columns(isExport, currency)}
							pagination={false}
							size='small'
						/>
					</div>
				</div>
			</div>
		</div>
	);
}
export default OrderDisplayItemRow;
